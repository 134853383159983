<template>
  <CRow>
    <CCol sm="12">

      <CCard>
        <CCardHeader>
          <CIcon name="cil-group"/>
          <h5 class="d-inline ml-2">All Users Table</h5>
        </CCardHeader>

        <BackendTable/>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import BackendTable from './BackendTable'

export default {
  name: 'AdvancedUsers',
  components: {
    BackendTable,
  }
}
</script>
