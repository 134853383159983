<template>
  <CCardBody>
    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Country Filter -->
            <div class="col-lg mb-2">
              <v-select
                class="v-select-filter"
                placeholder="Country.."
                v-model="filters.country"
                :options="authCountries"
                :reduce="c => c.value"
                :searchable="false"
                @input="getAllUsers"
              />
            </div>

            <!-- Language Filter -->
            <div class="col-lg mb-2">
              <v-select
                class="v-select-filter"
                placeholder="User Language.."
                v-model="filters.users.selectedLanguages"
                :options="allLanguages"
                :reduce="c => c.value"
                :searchable="false"
                multiple
                @input="getAllUsers"
              />
            </div>

            <!-- Type Filter -->
            <div class="col-lg mb-2">
              <v-select
                class="v-select-filter"
                placeholder="Type.."
                v-model="filters.users.selectedType"
                :options="allTypes"
                :searchable="false"
                @input="getAllUsers"
              />
            </div>

            <!-- Search -->
            <div class="col-lg-12 col-filter mb-0">
              <CInput
                class="mb-0"
                type="search"
                placeholder="Search for something else ..."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <!--
      :column-filter="{ external: true, lazy: true }"
      :column-filter-value.sync="columnFilterValue"
      :sorter-value.sync="sorterValue"
      :table-filter-value.sync="tableFilterValue"
      :active-page="1"
      :table-filter="{
        label: 'Filter:',
        placeholder: 'type i#XX for ID anything else ...',
        external: true,
        lazy: true
      }"
      @update:table-filter-value="tableSearch"
      @update:column-filter-value="columnFilter"
      -->
    <CDataTable
      hover
      striped
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No users found!',
      }"
      clickable-rows
      @row-clicked="rowClicked"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >
      <!-- User Icon -->
      <template #image_icon="{item}">
        <td>
          <CLink @click="imageClicked(item)">
            <CImg
              :src="item.image_icon || item.gravatar"
              class="c-avatar-img"
              style="width: 36px; height: 36px"
              placeholderColor="lightgray"
            />
          </CLink>
        </td>
      </template>

      <!-- Country -->
      <template #country="{item}">
        <td>
          <template v-if="item.country">
            <CIcon class="mr-1" :content="$options.flagSet[item.country.flag]" size="lg" />
            {{ item.country.label }}
          </template>
        </td>
      </template>

      <!-- User Language -->
      <template #lang="{item}">
        <td>
          <!-- nl -> cifNl -->
          <CIcon v-if="item.lang == 'en'" class="flag" :height="14" :content="$options.flagSet['cifGb']" />
          <CIcon v-else class="flag" :height="14" :content="$options.flagSet['cif' + item.lang.charAt(0).toUpperCase() + item.lang.slice(1)]" />
          {{ item.lang.toUpperCase() }}
        </td>
      </template>

      <!-- Orders -->
      <template #orders="{item}">
        <td>
          <div>
            Real Orders:
            <CLink v-if="item.orders_count" :to="{name: 'UserOrderList', params: { user_id: item.id }}">
              <strong>{{ item.orders_count }}</strong>
            </CLink>
            <span v-else>0</span>
          </div>
          <div>
            Latest:
            <CLink v-if="item.latest_order" :to="{name: 'Order Details', params: { id: item.latest_order.id }}">
              <strong>{{ item.latest_order.created_at }}</strong>
            </CLink>
            <span v-else>-</span>
          </div>
        </td>
      </template>

      <!-- Current Point / Used -->
      <template #point="{item}">
        <td class="text-center">
          <strong class="text-success">{{ item.point?.points ?? 0 }}</strong>
          /
          <strong class="text-danger">{{ item.total_points_used }}</strong>
        </td>
      </template>

      <!-- Reviews Count -->
      <template #reviews_count="{item}">
        <td class="text-center">
          <CLink :to="{name: 'UserReviews', params: { user_id: item.id }}">
            <strong>{{ item.reviews_count }}</strong>
          </CLink>
        </td>
      </template>

      <!-- Contact -->
      <template #contact="{item}">
        <td>
          <div><CIcon name="cil-at" class="mr-1" />{{ item.email }}</div>
          <div><CIcon name="cil-phone" class="mr-1" />{{ item.mobile }}</div>
          <div><CIcon name="cil-location-pin" class="mr-1" />{{ item.city | ucfirst }}</div>
        </td>
      </template>

      <template #usertype="data">
        <td>
          <CBadge :color="getBadge(data.item.usertype)">
            {{ data.item.usertype }}
          </CBadge>
          <div v-if="data.item.usertype === 'Admin'" v-c-tooltip="{content: '2FA Mobile Number', placement: 'bottom'}">
            <CIcon name="cil-mobile" class="mr-1" />
            <strong v-if="data.item.tfamobile">{{ data.item.tfamobile }}</strong>
            <span v-else>-</span>
          </div>
        </td>
      </template>

      <template #type="data">
        <td>
          <CBadge :color="getBadge(data.item.type)">
            {{ data.item.type }}
          </CBadge>
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="text-center">
          <CButton
            size="sm"
            color="info"
            variant="ghost"
            shape="pill"
            :to="{ name: 'Edit User', params: { id: item.id }}"
          >
            <CIcon name="cil-align-left" />
          </CButton>
        </td>
      </template>
    </CDataTable>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

  </CCardBody>
</template>

<script>
import _debounce from 'lodash/debounce';
import { flagSet } from '@coreui/icons'
import { mapState } from 'vuex'
import { Role } from "../../../router/role";

export default {
  name: "BackendTable",
  flagSet,
  data() {
    return {
      usersData: [],
      loadedItems: [],
      fields: [
        // { key: "actions",       label: "",                  filter: false,        sorter: false, _style: "text-align: center;" },
        { key: "id",            label: "ID",                    _style : "width: 5%" },
        { key: "image_icon",    label: "Image",                 _style: "max-width: 100px;" },
        { key: "country",       label: "Country",               _style: "min-width: 120px; width: 15%" },
        { key: "lang",          label: "Language",              _style: "min-width: 100px;" },
        { key: "first_name",    _style: "min-width: 140px;" },
        { key: "orders",        _style: "min-width: 160px;" },
        { key: "point",         label: "Current Point / Used",  _style: "max-width: 160px; text-align: center;", sorter: false},
        { key: "reviews_count", label: "Reviews Count",         _style: "max-width: 100px; text-align: center;" },
        { key: "contact",       _style : "min-width: 200px;" },
        { key: "usertype",      label: "User Type",             _style : "min-width: 160px;" },
        { key: "type",          sorter: false }
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Filters
      allLanguages: [],
      allTypes: ['All', 'Admin', 'Registered', 'Unregistered'],
    };
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'All Users', query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    ...mapState(['filters']),

    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() {
      // if(this.$route.query.page)
      //   return parseInt(this.$route.query.page);
      // return 1;
      return parseInt(this.$route.query.page) || 1;
    },
    isSuperAdmin() { return this.authUserRoles.includes(Role.SuperAdmin) },
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllUsers();
    }, 1000);
  },

  async mounted() {
    if (this.isSuperAdmin) {
      this.fields.unshift({
        key: "actions",
        label: "",
        filter: false,
        sorter: false,
        _style: "text-align: center;"
      });
    }

    this.activePage =  this.queryPage;
    await Promise.all(
      [this.getAllLanguages(), this.getAllUsers()]
    );
  },

  methods: {
    getBadge(usertype) {
      switch (usertype) {
        case "User":
        case "Registered":
          return "success";
        case "Admin":
          return "primary";
        case "Banned":
        case "Unregistered":
          return "danger";
        default:
          "warning";
      }
    },
    async getAllUsers() {
      this.loading = true;

      var url = new URL(this.$backend.USERS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,

          // Filter by
          country: this.filters.country,
          langs: this.filters.users.selectedLanguages,
          type: this.filters.users.selectedType,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data.map((el) => {
              return {
                ...el,
                total_points_used: el.orders_using_points_vouchers.reduce(
                  (total, order) => { return total + parseFloat(order.total_points_used ?? 0); }, 0
                ),
                country: this.authCountries.find((c) => c.iso2 === el.country?.toLowerCase())
              }
            });

            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllUsers();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value
      this.getAllUsers();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllUsers();
    },
    // tableSearch(search) {
    //   this.search = search;
    //   this.getAllUsers();
    // },
    // columnFilter(filter) {
    //   console.log(JSON.stringify(filter))

    //   Object.keys(filter).forEach((key) => {
    //     console.log(key, filter[key]);
    //   });
    // },
    rowClicked(item, index, column, e) {
      if (!['INPUT', 'LABEL', 'BUTTON', 'svg', 'path', 'A', 'IMG', 'SPAN', 'DIV', 'rect', 'STRONG'].includes(e.target.tagName)) {
        this.$router.push({ name: 'User', params: { id: item.id } })
      }
    },

    imageClicked(item) {
      this.$router.push({ name: 'User', params: { id: item.id } })
    },

    async getAllLanguages() {
      await this.$axios.get(this.$backend.SETTINGS.GET_LANGUAGES)
        .then((response) => (this.allLanguages = response.data));
    },

    // Filters
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.$store.commit("setCountryFilter", null);
        this.filters.users.selectedLanguages = [];
        this.filters.users.selectedType = null;
        this.search = "";
        this.getAllUsers();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>

<style>
/* table-filter */
@media (min-width: 576px) {
  label.mfe-2 + input[type="text"] {
    width: 50% !important;
  }
}
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
</style>
